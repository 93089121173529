<template>
  <div>
    <a-alert v-if="iconLoading" :message="msg" :type="typ" />
    <b-form @submit="onSubmit">
      <a-button-group>
        <a-button :disabled="counts>19" @click="add">
          <b-icon style="margin-right: 0.3rem;" scale="1.1" icon="person-plus" variant="muted"></b-icon>新增帳號           
          <span
            class="badge badge-info"
            style="margin-left:0.5rem;font-size: 0.7rem;"
            v-text="counts"
          />
        </a-button>
        <a-button type="primary" :disabled="checkitem" @click="onSubmit">
          <b-icon style="margin-right: 0.3rem;" scale="1.1" icon="caret-right-square" variant="muted"></b-icon>
          確認送出
        </a-button>
      </a-button-group>
      <span
        v-if="textFields.length>0"
        style="margin-left:1rem;font-size: 14px;"
        class="badge badge-pill badge-success"
        v-text="' 隨機密碼 : '+ DayPassWord"
      />
      <p></p>

      <div v-for="(textField, i) in textFields" :key="i" class="ddic">
        <div>
          <h6>
            <span
              v-if="textField.value3 || textField.value4 || textField.value5 || textField.value6"
              class="badge badge-danger"
            >資料尚未輸入完整</span>
          </h6>
          <div
            class="row"
            style="border-width:0.1rem;border-style:dashed;border-color:#ebebeb;padding:0.4rem;margin-bottom: 1rem;"
          >
            <div class="col-sm">
              <b-form-group
                id="input-group-1"
                :description="textField.value4? '該帳號已存在, 請使用其它帳號':''"
                label-for="input-1"
              >
                <label for="input-group-1">
                  <code>*</code>登入帳號 :
                </label>
                <a-button @click="remove(i)" type="link" size="small">(刪除)</a-button>
                <a-switch
                  checked-children="加入"
                  un-checked-children="不加入"
                  @change="onChange(i,textField.checked)"
                  :checked="textField.checked"
                  default-checked
                />(會員)
                <b-form-input
                  id="input-1"
                  v-model="textField.account"
                  @blur="checkAccount(i)"
                  autocomplete="off"
                  placeholder="輸入帳號"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-sm">
              <b-form-group id="input-group-2" label-for="input-2">
                <label for="input-group-2">
                  <code>*</code>顯示名稱 :
                </label>
                <b-form-input
                  id="input-2"
                  v-model="textField.username"
                  @blur="checkAddress(i)"
                  type="text"
                  placeholder="輸入名稱 "
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-sm">
              <b-form-group id="input-group-3" label-for="input-3">
                <label for="input-group-3">
                  <code>*</code>地址 :
                </label>
                <a-button @click="getAddress(i)" type="link" size="small">(選取地址)</a-button>
                <b-form-input
                  id="input-3"
                  v-model="textField.address"
                  @blur="checkAddress(i)"
                  type="text"
                  placeholder="輸入地址 "
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-sm">
              <b-form-group id="input-group-4" label-for="input-4">
                <label for="input-group-4">
                  <code>*</code>聯絡電話 :
                </label>
                <b-form-input
                  id="input-4"
                  v-model="textField.phone"
                  @blur="checkAddress(i)"                  
                  type="text"
                  placeholder="輸入電話 "
                  autocomplete="off"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-sm">
              <b-form-group id="input-group-5" label-for="input-5">
                <label for="input-group-5">
                  確認資料
                </label>
                <div>
                  <b-button @click="checkAddress(i)" pill variant="primary">確認</b-button>                  
                </div>                
              </b-form-group>              
            </div>            
          </div>
        </div>
      </div>
    </b-form>
    <b-modal
      ref="my-address"
      id="modal-2"
      title="地址選取"
      @hide="onHide"
      centered
      hide-footer
      hide-header
    >
      <div class="text-right">
        <a-button @click="closeModal()" type="link" size="small">(關閉)</a-button>
        <Addresscom @toaddress="toaddress" />
      </div>
    </b-modal>
    <b-modal
      ref="my-loading"
      id="modal-2"
      title="Loading"
      @hide="onHide"
      centered
      hide-footer
      hide-header
    >
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
        <strong>Loading....</strong>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { userService } from "../../../_services/";
import Addresscom from "../../../components/Addresslist.vue";
import * as moment from "moment/moment";
const postData = params => {
  return userService.registlist(params);
};

export default {
  name: "Inputmenber",
  components: {
    Addresscom
  },
  data() {
    return {
      textFields: [], //模板連結
      result: null,
      iconLoading: false,
      counts: 0,
      index: null,
      msg: "",
      DayPassWord: this.getdate(),
      typ: "success"
    };
  },
  mounted() {},
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["datas", "defaultval"]),
    checkitem() {
      let rs = true;
      let count = 0;
      for (let i in this.textFields) {
        if (
          this.textFields[i].value6 == true ||
          this.textFields[i].value5 == true ||
          this.textFields[i].value4 == true ||
          this.textFields[i].value3 == true
        ) {
          count += 1;
        }
      }
      if (count == 0 && this.textFields.length > 0) {
        rs = false;
      }
      return rs;
    }
  },

  methods: {
    add() {
      this.counts += 1;
      this.textFields.push({
        account: "",
        address: "",
        username: "",
        phone:"",
        value3: true,
        value4: false,
        value5: true,
        value6: true,        
        checked: true,
        citys : "",
        villages : "",
        towns :"",
      });
    },
    checkName(i) {
      if (this.textFields[i] != undefined) {
        return this.textFields[i].account.length > 1 ? true : false;
      }
      return false;
    },
    remove(index) {
      //移除連結
      this.counts -= 1;
      this.textFields.splice(index, 1);
    },
    getAddress(i) {
      this.index = i;
      this.$refs["my-address"].show();
    },
    toaddress(val,city,village,town) {
      this.textFields[this.index].address = val;
      this.textFields[this.index].citys = city;
      this.textFields[this.index].villages = village;
      this.textFields[this.index].towns = town;      
    },   

    onChange(i, checked) {
      this.textFields[i].checked = !checked;
    },
    getdate(val) {
      return this.makeid(5) + moment(val).format("YYYYDDHHmm");
    },

    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    async checkAccount(i) {
      if (this.textFields[i].account !== "") {
        await userService.checkAcct(this.textFields[i].account).then(rs => {
          this.textFields[i].account.length > 0 &&
          this.textFields[i].address.length > 0 &&
          this.textFields[i].username.length > 0 &&
          this.textFields[i].phone.length > 0
            ? ((this.textFields[i].value3 = false),
              (this.textFields[i].value5 = false),
              (this.textFields[i].value6 = false))
            : ((this.textFields[i].value3 = true),
              (this.textFields[i].value5 = true),
              (this.textFields[i].value6 = true));
          rs.data.verify == true
            ? (this.textFields[i].value4 = true)
            : (this.textFields[i].value4 = false);
          if (rs.data.verify == false) {
            this.textFields.filter(el => {
              return (
                el.account.toLowerCase() ===
                this.textFields[i].account.toLowerCase()
              );
            }).length > 1
              ? (this.textFields[i].value4 = true)
              : (this.textFields[i].value4 = false);
          }
        });
      }
    },
    checkAddress(i) {
      this.textFields[i].account.length > 0 &&
      this.textFields[i].address.length > 0 &&
      this.textFields[i].username.length > 0 &&
      this.textFields[i].phone.length > 0
        ? this.textFields[i].value4 == true && this.textFields[i].value5 == true && this.textFields[i].value6 == true
          ? ((this.textFields[i].value3 = true),
            (this.textFields[i].value5 = true),
            (this.textFields[i].value6 = true))
          : ((this.textFields[i].value3 = false),
            (this.textFields[i].value5 = false),
            (this.textFields[i].value6 = false))
        : ((this.textFields[i].value3 = true),
          (this.textFields[i].value5 = true),
          (this.textFields[i].value6 = true));
    },

    async onSubmit(event) {
      event.preventDefault();
      this.$refs["my-loading"].show();
      this.iconLoading = true;
      let datas = [];
      for (let obj of this.textFields) {
        let tmp1 = obj.account;
        let tmp2 = obj.address;
        let tmp3 = obj.username;
        let tmp4 = obj.phone;
        let tmp5 = obj.citys;
        let tmp6 = obj.villages;
        let tmp7 = obj.towns
        datas.push({
          account: tmp1.toString().trim(), //帳號
          address: tmp2.toString().trim(), //地址
          username: tmp3.toString().trim(), //顯示名稱
          phone: tmp4.toString().trim(), //連絡電話
          citys: tmp5.toString().trim(), 
          villages: tmp6.toString().trim(),
          towns: tmp7.toString().trim(),
          checked: obj.checked, //確認資料狀態
          oid: this.defaultval.id, //隸屬組織ID
          password: this.DayPassWord //使用者密碼
        });
      }
      if (datas.length > 0) {
        postData(datas).then(({ data }) => {
          let code = data.code;
          if (code == 200) {
            this.$refs["my-loading"].hide();
            this.textFields = [];
            this.counts = 0;
            this.typ = "success";
            this.msg = "新增資料成功";
            setTimeout(() => {
              this.iconLoading = false;
            }, 1000);

          } else {
            this.$refs["my-loading"].hide();
            this.typ = "danger";
            this.msg = "新增資料失敗";
            setTimeout(() => {
              this.iconLoading = false;
            }, 1000);

          }
        });
      }
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    closeModal() {
      this.$refs["my-address"].hide();
    }
  }
};
</script>
<style scoped>
</style>